ul.tx-indexedsearch-browsebox {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;

  li {
    display: inline;

    a, span {
      display: inline-block;
      padding: 6px 14px;
      background-color: #fff;
      border: 2px solid #ddd;
      border-radius: 20px;
    }

    &.tx-indexedsearch-browselist-currentPage {
      a, span {
        color: white;
        cursor: not-allowed;
        background-color: $red-color;
        border-color: $red-color;
        text-decoration: none;

        &:hover, &:focus {
          color: white;
          cursor: not-allowed;
          background-color: $red-color;
          border-color: $red-color;
          text-decoration: none;
        }
      }
    }
  }
}