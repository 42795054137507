@import "../../Lib/Bootstrap/stylesheets/bootstrap.scss";

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1

$screen-xl:            1500px !default;
$screen-xl-min:        $screen-xl !default;

// So media queries don't overlap when required, provide a maximum
$screen-lg-max:        ($screen-xl-min - 1) !default;

// Large screen / wide desktop
$container-xl-desktop: (1440px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-xl:         $container-xl-desktop !default;

$modal-xl:             1200px !default;

// Mixins
@mixin make-xl-grid-columns($i: 1, $list: ".col-xl-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xl-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($grid-gutter-width / 2));
    padding-right: floor(($grid-gutter-width / 2));
  }
}

// Visibility utilities
// Note: Deprecated .visible-xs, .visible-sm, .visible-md, and .visible-lg as of v3.2.0

@include responsive-invisibility('.visible-xl');

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
  display: none !important;
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include responsive-visibility('.visible-lg');
}
.visible-lg-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: block !important;
  }
}
.visible-lg-inline {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline !important;
  }
}
.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-xl-min) {
  @include responsive-visibility('.visible-xl');
}
.visible-xl-block {
  @media (min-width: $screen-xl-min) {
    display: block !important;
  }
}
.visible-xl-inline {
  @media (min-width: $screen-xl-min) {
    display: inline !important;
  }
}
.visible-xl-inline-block {
  @media (min-width: $screen-xl-min) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  @include responsive-invisibility('.hidden-lg');
}

@media (min-width: $screen-xl-min) {
  @include responsive-invisibility('.hidden-xl');
}


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
.container {
  @media (min-width: $screen-xl-min) {
    width: $container-xl;
  }
}

// Columns
//
// Common styles for small and large grid columns
@include make-xl-grid-columns;

// XL grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: $screen-xl-min) {
  @include make-grid(xl);
}

// Scale up the modal
@media (min-width: $screen-lg-min) {
  .modal-xl { width: $modal-xl; }
}

.dropdown-menu {
  padding: 10px 0;
}