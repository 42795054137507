.news-single {
  .article {
    .news-img-wrap {
      width: auto;
      float: left;
      margin: 0 30px 30px 0;
    }
    .news-backlink-wrap {
      clear: both;
    }
    h3.teaser-text {
      font-weight: normal;
      color: #666;
      margin-bottom: 30px;
      font-size: 28px;
    }
  }
  .footer {
    border: 0 none;
    padding: 0;
    margin: 0;
  }
}