
body.changeCursor .banner{
	padding-top:280px;
	-webkit-transition: All 0.60s ease;
	-ms-transition: All 0.60s ease;
	transition: All 0.60s ease; 
}




.banner{
	-webkit-transition: All 0.70s ease;
	-ms-transition: All 0.70s ease;
	transition: All 0.70s ease;
	z-index: 1;

	&:after {
		content: '';
		position:absolute;
		left:0;
		bottom:-234px;
		width:100%;
		@include background(style1,round,svg,no-repeat);
		height:468px;
		z-index:100;
		pointer-events: none;
	}

	position: relative;

	.img{
		background-repeat: no-repeat;
		background-size:cover;
		background-position:center top;
		position: absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		z-index: -1;
		img{
			display:none;
		}
	}
	width:100%;
	min-height:88vh;
	padding-top: 213px;

	/* Banner Slider */

	.slick-slider {
		.slide-item {
            .banner-overlay {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0.4;
              z-index: 1;
            }
			.bg-image {
				min-height: 88vh;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
				position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
			}
			.container {
				//padding-top: 180px;
			}
			.element-text {
				position: absolute;
				padding-left: 3%;
                z-index: 10;
                top: 31%;
				@media (min-width: 1290px) {
                  padding-left: 0;
				}

				padding-right: 50%;
				opacity: 0;

				.title {
					color: white;
				}
				p {
					line-height: 40px;
					letter-spacing: .4px;
					margin-bottom: 45px;
					font-size: 22px;
					color: #fff;
				}
			}

			&.slick-active {
				.element-text {
					animation: fade-in-right 0.75s both cubic-bezier(0.215, 0.61, 0.355, 1);
					animation-delay: 1s;
				}
			}
		}
		.slick-arrow {
			width: 50px;
			height: 50px;
			&:before {
				font-family: icomoon!important;
				speak: none;
				font-size: 50px;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
			}
		}
		.slick-next {
			@extend .icon-chevron-right;
			right: 25px;
			z-index: 2;
		}
		.slick-prev {
			@extend .icon-chevron-left;
			left: 25px;
			z-index: 2;
		}

		.slick-dots {
			bottom: 45px;
			z-index: 1000;

			li {
				button:before {
					content: "";
					background-color: black;
					border-radius: 50%;
					transition: all .3s ease-in-out;
					height: 16px;
					width: 16px;
				}
				&:hover,
				&.slick-active {
					button:before {
						opacity: 1;
						background-color: red;
					}
				}
				&:hover {
					button:before {
						opacity: 1;
						background-color: $red3;
					}
				}
			}
		}

	}


	/*===============*/
	.banner-text{
		float:left;
		color:#FFF;
		h1{
			margin-bottom:30px;
		}
		p{
			line-height:40px;
			letter-spacing:0.4px;
			margin-bottom:45px;
			font-size:30px;
		}
		.btn.active{
			float:left;
		}
		.btn-group.bootstrap-select{
			width:auto !important;
			margin-left:15px;
			.btn{
				border:2px solid $grey-color;
				color:#fff;
				line-height:50px;
			}
		}
	}
	.bs-caret{
		@include background(style1,wight-arrow,png,no-repeat);
		
	}
}
#page.inner-page{
	.banner{
		min-height:680px;
		.banner-text{
			h1{
				color:$red-color;
			}
		}
	}
}

.main .banner {
	padding-top: 0px;
}

.banner {
	.banner-img {
		animation: fade-in-left 1.5s both cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-delay: 1s;
	}
}

@media (max-width: $screen-md) {
	h1, .h1{
		font-size:40px;
	}
	.banner {
		min-height:auto;
		text-align:center;
		.banner-img{
			position:relative;
		}
		.banner-text {
			float: none;
			p{
				font-size:22px;
				line-height:30px;
				margin-bottom:20px;
			}
			.btn-group.bootstrap-select{
				margin:15px 0 0 0;
				min-width:100%;
			}
			.btn.active{
				width:100%;
			}
		}
		.banner-img{
			width:100%;
			margin-top:20px;
		}

		.slick-slider {

			.slide-item {
				.element-text {
					padding: 0 25%;
					left:0;
					h1 {
						font-size: 30px;
					}
					p {
						font-size: 18px;
						line-height: 26px;
						margin-bottom: 20px;
					}
					.btn {
						font-size: 16px;
						padding: 0px 15px 0px;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	h1, .h1{
		font-size:40px;
	}
	.banner {
		.slick-slider {
			.slick-arrow {
				display: none !important;
			}
			.slide-item {
				.element-text {
					padding: 0 10%;
					left:0;
					h1 {
						font-size: 20px;
					}
					p {
						font-size: 18px;
						line-height: 26px;
						margin-bottom: 20px;
					}
					.btn {
						font-size: 16px;
						padding: 0px 15px 0px;
					}
				}
			}
		}
	}
}
