/**
 * Styling owl carousel slider
 */
//@import '../../../../Css/Lib/owl.carousel.css';

.owl-carousel {
	width: 100%;
	overflow: hidden;

	.owl-controls {
		position: absolute;
		bottom: 10px;
		left: 40%;

		.owl-page {
			width: 20px;
			height: 20px;
			float: left;
			margin-right: 5px;
			background-color: $slider-controls-color;
			border-radius: 20px;

			&.active {
				opacity: 0.6;
			}

			&:hover {
				opacity: 0.6;
				cursor: pointer;
			}
		}
	}
}
