$border-color: white;
$border-width: 3px;

@keyframes border {
  0% {
    box-shadow: 50px -50px 0 $border-width $border-color, -50px -50px 0 $border-width $border-color, -50px 50px 0 $border-width $border-color, 50px 50px 0 $border-width $border-color, 0 0 0 $border-width white;
  }
  25% {
    box-shadow: 0 -125px 0 $border-width $border-color, -50px -50px 0 $border-width $border-color, -50px 50px 0 $border-width $border-color, 50px 50px 0 $border-width $border-color, 0 0 0 $border-width $red-color;
  }
  50% {
    box-shadow: 0 -125px 0 $border-width $border-color, -125px 0px 0 $border-width $border-color, -50px 50px 0 $border-width $border-color, 50px 50px 0 $border-width $border-color, 0 0 0 $border-width $red-color;
  }
  75% {
    box-shadow: 0 -125px 0 $border-width $border-color, -125px 0px 0 $border-width $border-color, 0px 125px 0 $border-width $border-color, 50px 50px 0 $border-width $border-color, 0 0 0 $border-width $red-color;
  }
  100% {
    box-shadow: 0 -125px 0 $border-width $border-color, -125px 0px 0 $border-width $border-color, 0px 125px 0 $border-width $border-color, 120px 40px 0 $border-width $border-color, 0 0 0 $border-width $red-color;
  }
}