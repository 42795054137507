footer {
	background: #333333;

	.container {
		padding-top: 0;
		padding-bottom: 0;
	}
	.footer-top
	{
		background: #292929;
		padding:$paddingLarge 0;


		@media(max-width: $screen-sm-max) {
			padding-top: $paddingMedium;
			padding-bottom: $paddingMedium;
		}

		overflow:hidden;
		text-align:center;

		.social-media {
			display: inline-block;
			margin:0 0 0 15px;
			vertical-align: bottom;

			a {
				float: left;
				width:40px;
				height:40px;
				background:$grey-color;
				display:inline-block;
				margin:0 5px;
				padding: 3px 5px;
				color:#292929;
				font-size:24px;
				@include border-radius(100px);
				-webkit-transition: all 150ms ease-in-out;
				-moz-transition: all 150ms ease-in-out;
				-ms-transition: all 150ms ease-in-out;
				-o-transition: all 150ms ease-in-out;
				transition: all 150ms ease-in-out; 
				&:hover{
					background:$red-color;
					color:#fff;
					display: inline-block;
				}
				&:not(:first-child) {
					padding: 5px 5px;
				}
			}
		}
	}
	h2{
		color:$grey-color;
		font-size: 34px;
		padding:0;
		line-height:40px;
		display: inline-block;
		&::before{
			width:0;
		}
	}
	.footer-middle{
		border-bottom:1px solid #404040;
		padding-top:78px;
		padding-bottom:$padding-30;
		.fot-nav{
			width:auto;
			padding:0px;
			margin:20px 0 0 0;
			list-style:none;
			li{
				font-size:18px;
				line-height:40px;
				float:left;
				padding-right:33px;
				a{
					color:$grey-color;
					text-decoration: none;
					&.active,
					&:hover{
						color:#fff;
					}
				}
			}
		}
		.detail{
			float:right;
			h5{
				float:left;
				color:#fff;
				margin:20px 0;
				line-height:39px;
			}
			.fot-logo{
				float:left;
				padding-left:$padding-30;
				width:110px;
				height:110px;
				img{
					max-width:100%;
					max-height:100%;
				}
			}
		}
	}
	.footer-bottom{
		padding:25px 0 68px;
		p{
			font-size:16px;
			line-height:30px;
			color:#666666;
			margin:0;
			a{
				color:#999999;
				text-decoration:none;
				&:hover{
					color:#fff;
				}
			}
		}
	}
}
@media (max-width: 990px) {
	footer .footer-middle .fot-nav li{
		padding-right:10px;
	}
	footer h2{
	margin-bottom:15px;
	}
}
@media (max-width: $grid-float-breakpoint-max) {
	footer {
		text-align:center;
		.footer-top{
			padding:30px 0;
			.social-media{
				margin:15px 0 0;
			}
		}
		.footer-middle{
			padding:30px 0;
			text-align: center;
			.fot-nav {
				li{
					float:none;
					padding-right:10px;
					padding-left:10px;	
				}
			}
			.detail { 
				width:100%;
				h5{
					width:100%;
				}
				.fot-logo{
					width:100%;
					height:100%;
					text-align: center;
					padding-left:0px;	
				}
			}
		}
		.footer-bottom{
			padding:25px 0;
		}
	}
}