figure {
	img {
		width: 100%;
		height: auto;

		&.img-cropped {
			width: auto;
		}
	}

	&.inline {
		display: inline-block;
	}

	&.above-center img,
	&.below-center img {
		margin: 0 auto;
		display: block;
	}

	&.above-left img,
	&.below-left img {
		float: left;
	}

	&.above-right img,
	&.below-right img {
		float: right;
	}
}

.boxed {
	position: relative;

	img {
		width: 100%;
	}

	figcaption {
		position: absolute;

		&.content--top-right {
			top: 0;
			right: 0;
		}

		&.content--middle-center {
			top: 45%;
			left: 0;
			width: 100%;
			text-align: center;
		}
	}
}
