
.select-outline {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;

  border: 2px solid #808080;
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0 none;
    outline: none;
    padding: 15px 60px 15px 25px;
  }

  select::-ms-expand {
    display:none;
  }

  &:after {
    @extend .icomoon;
    content: $icon-chevron-down;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    right: 25px;
    top: 22px;
    pointer-events: none;
  }
}