
$menu-bg-color: #292929;
$menu_width: 250px;
$menu-btn-line-height: 1px;
$menu-btn-line-color: #fff;

@import "../../Lib/pushy/scss/pushy";

html,
body{
  -webkit-overflow-scrolling: touch;
  height: 100%;
  //overflow: auto;
}
html {
  overflow-x: hidden;
}

.pushy {
  font-size: 16px;
  background: $menu-bg-color;

  a {
    padding: 15px 20px;
    font-weight: normal;
    color: darken(#ffffff, 30%);

    &:hover, &:focus {
      color: white;
    }
  }

  hr {
    margin: 0 20px;
    border-color: rgba(255,255,255,0.3);
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li.active {
      a {
        color: white;
      }
    }
  }
}

.pushy-content {
  >ul {
    >li {
      >a {
        text-transform: uppercase;
        font-size: 18px;
      }

      & + li {
        border-top: 1px solid darken(#ffffff, 70%);
      }
    }
    + ul {
      border-top: 1px solid darken(#ffffff, 70%);
    }
  }
}
.pushy-open-left,
.pushy-open-right {
  .pushy {
    box-shadow: 0 0 30px rgba(0,0,0,0.5);
  }
}

// Searchform
.pushy-search {
  padding: 20px;

  .searchbox {
    position: relative;

    input[type^=text] {
      text-align: left;
      line-height: 50px;
      padding: 0 60px 0 10px;
      font-size: 16px;
      border-radius: 0;
      box-shadow: none;
    }
  }
  .searchbox-sword {
    height: 50px;
    color: white;
    background: rgba(255,255,255,0.1);
    padding-left: 10px;
    border: 0 none;
    padding-right: 10px;
  }
  .btn-search {
    background: $input-bg;
    color: $input-color;
    border: 1px solid $input-border;
  }
}

/* Submenu Appearance */
.pushy-submenu {

  ul{
    transition: max-height 0.2s ease-in-out;

    .pushy-link{
      transition: opacity 0.2s ease-in-out;
    }

    li {
      border-top: 1px solid darken(#ffffff, 80%);
    }
  }

  /* Submenu Icon */
  a {
    position: relative;
    padding-right: 40px;
    font-weight: 300;

    color: darken(#ffffff, 40%);

    &:after {
      content: none;
      background: none;
    }
    > span {
      display: block;
      position: absolute;
      top: 8px;
      right: 10px;
      padding: 10px;

      transform: rotate(0deg);
      transition: transform 0.2s;
    }
  }
}

/* Submenu Movement */
.pushy-submenu-closed {
  ul {
    max-height: 0;
    overflow: hidden;
  }

  .pushy-link {
    opacity: 0;
  }
}

.pushy-submenu-open {
  > ul {
    max-height: none;
  }

  .pushy-link {
    opacity: 1;
  }

  /* Submenu Icon */
  > a {
    > span {
      transform: rotate(180deg);
    }
  }
}

.no-csstransforms3d {
  .pushy-submenu-closed {
    ul {
      max-height: none;
      display: none;
    }
  }
}


/* Menu Button */
.menu-btn {
  width: 30px;
  height: 22.5px;
  position: relative;
  float: left;
  cursor: pointer;
  margin-top: 37px;

  transform: rotate(0deg);
  transition: .5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: $menu-btn-line-height;
    width: 100%;
    background: $menu-btn-line-color;
    opacity: 1;
    left: 0;

    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }
}
/* Menu Button Open */
[class^=pushy-open-],
[class*="pushy-open-"] {
  overflow-x: hidden;

  .menu-btn span {
    &:nth-child(1) {
      top: 9px;
      width: 0%;
      left: 50%;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
    }
    &:nth-child(4) {
      top: 9px;
      width: 0%;
      left: 50%;
    }
  }
}

.pushy-socialmedia {
  .nav-pills {
    margin: 10px auto;
    text-align: center;

    > li {
      float: none;
      display: inline-block;
    }
  }
}

.pushy-close {
  position: absolute;
  right: 0px;
}
