/**
 * Global Standard Variables
 */
/* Import Library Variables
-----------------------------------------------------------------*/
@import "Lib/Bootstrap/3.3.6/bootstrap/variables";
@import "Lib/FontAwesome/4.6.3/variables";

/* Override FontAwesome Variables
-----------------------------------------------------------------*/
$fa-font-path: "/typo3conf/dia_base/Resources/Font/FontAwesome/4.6.3" !default;

/* Standard Text content Variables
-----------------------------------------------------------------*/
$fontSizeH1: 40px;
$marginH1: 20px;
$fontSizeH2: 34px;
$marginH2: 20px;
$fontSizeH3: 28px;
$marginH3: 16px;
$fontSizeH4: 22px;
$marginH4: 10px;
$fontSizeH5: 18px;
$marginH5: 6px;
$fontSizeH6: 16px;
$marginH6: 0;

$fontSizeText: 18px;
$fontSizeTextSmall: 16px;

/* Standard Colors Variables
-----------------------------------------------------------------*/
$colorBgBody: rgba(234,234,234,.8);
$colorBgContainer: transparent;
$colorBgContainerFooter: transparent;
$colorBgContainerHeader: transparent;

$colorTextHeader: #FFFFFF;
$colorTextFooter: #FFFFFF;
$colorTextMain: #010101;

/* Standard Spacing Variables
-----------------------------------------------------------------*/
$paddingNone: 0;
$paddingDefault: 15px;
$paddingSmall: 15px;
$paddingMedium: 30px;
$paddingLarge: 60px;
$marginNone: 0;
$marginDefault: 15px;
$marginSmall: 15px;
$marginMedium: 30px;
$marginLarge: 60px;

/* Standard Controls Variables
-----------------------------------------------------------------*/
$slider-controls-color: #337ab7;

/* Standard responsive breaking points
-----------------------------------------------------------------*/
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;

// Xtra large screen / cinema display
//** Deprecated `$screen-xl` as of v3.0.1
$screen-xl: 1600px !default;
$screen-xl-min: $screen-xl !default;
$screen-lg-max: ($screen-xl-min - 1) !default;

//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
$container-xl: ($screen-xl - $grid-gutter-width) !default;
