/**
 * Cookie Information popup
 */
.container.cookie-info {
	display: none;
	background-color: #00FF00;
	width: 100%;
	position: fixed;
	z-index: 100;

	&.cookie-info-top {
		top: 0;
	}

	&.cookie-info-bottom {
		bottom: 0;
	}
}
