.element-header {
  margin-bottom: 0;
}

body {
  -moz-transition: -moz-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  -o-transition: -o-transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
}

#header-search {
  position: relative;
  z-index: 3000;
  display: none;
  overflow: hidden;
  width: 100%;
  padding: 23px 0;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  background: $dark-black-color;

  .container {
    position: relative;
  }

  .input-text {
    font-size: 24px;
    line-height: 30px;
    float: left;
    width: 82%;
    margin: 0;
    padding: 0 15px;
    color: #808080;
    border: none;
    background: none;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 15px;
    color: #fff;

    i {
      font-size: 22px;
      line-height: 30px;
      padding-right: 0;
      cursor: pointer;
      color: #999999;
    }
  }

  .search-button {
    float: left;

    i {
      font-size: 22px;
      line-height: 30px;
      color: #999999;
    }
  }
}

header {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 100%;

  .navbar {
    padding-top: 30px;
  }

  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }

  .bg-info {
    margin-top: 5px;

    a {
      font-size: 20px;
      font-weight: normal;
      line-height: 32px;
      padding: 2px 20px 0;
      text-decoration: none;
    }
  }

  .navbar-link {
    position: relative;
    float: left;
    margin-top: 12px;
    padding-right: 30px;

    .header-search-button {
      color: #fff;
      border: none;
      background: none;
    }

  }

  .navbar-collapse {
    padding-top: 23px;
  }

  .navbar-header {
    float: right;
  }

  .navbar-nav {
    padding-right: 15px;

    > li {
      padding-right: 15px;
      padding-left: 15px;

      .arrow-img {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        float: right;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }

      .back-link {
        font-weight: 600;
        display: none;
        float: left;
        width: 100%;
        padding-left: 0;
        cursor: pointer;
        color: #fff;
        border-bottom: 1px solid #fff;

        i {
          float: left;
          padding-left: 15px;
        }
      }

      //&:hover ul {
      //  display: none;
      //}
      //ul {
      //  display: none;
      //  padding: 8px 0;
      //  margin: 0;
      //  width: 210px;
      //  position: absolute;
      //  left: -33px;
      //  top: 44px;
      //  list-style: none;
      //  background: $light-grey-color;
      //  > li {
      //    width: 100%;
      //    line-height: 30px;
      //    font-size: 16px;
      //    a {
      //      padding: 0 15px;
      //      display: block;
      //      color: #666;
      //      line-height: 30px;
      //      text-decoration: none;
      //    }
      //    &:hover a {
      //      background: $dark-black-color;;
      //      color: #fff;
      //      display: block;
      //    }
      //  }
      //}
      > a {
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        display: inline-block;
        -moz-transition: background 0.3s, color 0.3s;
        -ms-transition: background 0.3s, color 0.3s;
        -o-transition: background 0.3s, color 0.3s;
        -webkit-transition: background 0.3s, color 0.3s;
        transition: background 0.3s, color 0.3s;
        text-transform: $text-uppercase;

        &.nav-link {
          @include triangle("top");

          &:before {
            top: 0;
            left: 0;
            width: 43px;
            height: 3px;
            -moz-transition: -webkit-transform 0.3s;
            -ms-transition: -webkit-transform 0.3s;
            -o-transition: -webkit-transform 0.3s;
            -webkit-transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            -moz-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -ms-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -o-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -webkit-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -moz-transform: scale3d(0, 2, 1);
            -ms-transform: scale3d(0, 2, 1);
            -o-transform: scale3d(0, 2, 1);
            -webkit-transform: scale3d(0, 2, 1);
            transform: scale3d(0, 2, 1);
            -moz-transform-origin: 0% 50%;
            -ms-transform-origin: 0% 50%;
            -o-transform-origin: 0% 50%;
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            background: $red-color;
          }

          &:hover,
          &:focus {
            @include triangle("top");
            color: $navbar-default-link-hover-color;

            &:before {
              -moz-transform: scale3d(1, 1, 1);
              -ms-transform: scale3d(1, 1, 1);
              -o-transform: scale3d(1, 1, 1);
              -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
          }
        }

        &.dropdown-toggle {
          font-size: 14px;
          padding: 0 8px;

          &:hover, &:focus {
            color: $red-color;
          }
        }
      }

      &.active a.nav-link {
        &:before {
          -moz-transform: scale3d(1, 1, 1);
          -ms-transform: scale3d(1, 1, 1);
          -o-transform: scale3d(1, 1, 1);
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }

      &:first-child {
        padding-left: 0;
      }
    }

    > .active > a.nav-link {
      &:hover,
      &:focus {
        color: $navbar-default-link-active-color;
        @include triangle("top");

        &:before {
          top: 0;
          left: 0;
          width: 43px;
          height: 3px;
          background: $red-color;
        }
      }
    }

    > .disabled > a.nav-link {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        @include triangle("top");

        &:before {
          top: 0;
          left: 0;
          width: 43px;
          height: 3px;
          background: $red-color;
        }
      }
    }
  }

  .navbar-brand {
    line-height: normal;
    float: left;
    height: auto;
    margin-left: 0;
    padding: 0;

    > img {
      display: block;
    }

  }

  .dropdown-menu {
    > li {
      > a {
        padding: 5px 20px;
      }
    }
  }
}

@media (max-width: $screen-lg-min) {
  header {
    .navbar-nav {
      > li {
        padding-right: 10px;
        padding-left: 10px;

        a {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: $screen-md-min) {
  header {
    .navbar-link {
      padding-right: 25px;
    }

    .navbar-nav {
      padding-right: 20px;

      > li {
        a {
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: $screen-sm-max) {
  .menu-open {
    position: fixed;
    background: $red-color;
  }
  header {
    .navbar {
      padding-top: 15px;
    }

    .navbar-brand {
      float: right;
    }

    .btn.bg-info {
      width: 100%;
      margin-top: 0;
      margin-bottom: 10px;
      text-align: center;
      @include border-radius(0px);
      border-top: 2px solid $red-color;
      background: $red-color;

      &:hover {
        background: $dark-black-color;
      }
    }

    .navbar-link {
      margin-top: 35px;
      margin-left: 25px;
      padding: 0;

      i {
        font-size: 24px;
      }
    }

    .navbar-header {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .navbar-toggle {
      float: left;
      margin: 40px 0 0 0;
      padding: 0;
      border: none;
      background: none !important;

      .icon-bar {
        width: 26px;
        height: 3px;
      }
    }

    .navbar-nav {
      position: relative;
      width: 261px;
      margin: 0;
      padding: 0;
      text-align: left;

      &.fot-nav-reposive {
        margin-top: 30px;
        border-top: 1px solid #fff;

        li {
          a {
            position: relative;
            display: block;
            text-decoration: none;
          }
        }
      }

      > li {
        padding: 0;

        > a {
          padding: 0 10px;
          border-bottom: 1px solid #fff;

          &:hover,
          &:focus {
            color: #fff;
            background: $dark-black-color;
            @include triangle("top");

            &:before {
              width: 100%;
              height: 0;
            }
          }
        }

        &.menu-two-level {
          position: static;
        }

        ul {

          background: $light-grey-color;

          &:after {
            margin-left: 0;
            content: " ";
            border: solid transparent;
            border-width: 0;
          }

          > li {
            font-size: 16px;
            line-height: 30px;
            width: 100%;

            &:hover a {
              display: block;
              color: #fff;
              background: $red-color;
            }
          }
        }

        .back-link,
        .arrow-img {
          line-height: 40px;
          display: block;
          text-align: center;
          text-transform: uppercase;

          i {
            font-size: 25px;
            line-height: 40px;
            color: #fff;
          }
        }

        ul {
          top: 0;
          left: 261px;
          min-width: 261px;
          margin-top: 0;
          background: none;

          li {
            float: left;

            .arrow-img {
              display: none;
            }

            a {
              line-height: 40px;
              color: #fff;

              &:hover,
              &:focus {
                color: #fff;
                background: $dark-black-color;
              }
            }

            &.active {
              color: #fff;
              background: $dark-black-color;
            }
          }
        }
      }

      > .active > a {
        &,
        &:hover,
        &:focus {
          color: #fff;
          background: $dark-black-color;
          @include triangle("top");

          &:before {
            width: 100%;
            height: 0;
          }
        }
      }

      > .disabled > a {
        &,
        &:hover,
        &:focus {
          color: $navbar-default-link-disabled-color;
          @include triangle("top");

          &:before {
            width: 100%;
            height: 0;
          }
        }
      }
    }

    .navbar-collapse {
      position: absolute;
      top: -1px;
      left: -262px;
      display: inline-block;
      overflow: hidden;
      width: 261px;
      height: 500px;
      margin: 0;
      padding: 0;
      -moz-transition: -webkit-transform 0.4s ease;
      -ms-transition: -ms-transform 0.4s ease;
      -o-transition: -webkit-transform 0.4s ease;
      -webkit-transition: -webkit-transform 0.4s ease;
      transition: transform 0.4s ease;
      border: none;
      background: $red-color;
    }
  }
}

//header .navbar-nav>li ul {
//  display: none;
//}

.menu-open header .navbar-toggle .icon-bar {
  position: relative;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.menu-open header .navbar-toggle .icon-bar:nth-child(1) {
  top: 5px;
  left: 0;
  width: 26px;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-open header .navbar-toggle .icon-bar:nth-child(2) {
  width: 0;
  opacity: 0;
}

.menu-open header .navbar-toggle .icon-bar:nth-child(3) {
  top: -9px;
  left: 0;
  width: 26px;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  -webkit-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-open header .navbar-toggle {
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.page-overlay {
  position: absolute;
  z-index: -1;
  top: 76px;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  opacity: 0;
  background: black;
}

.open .page-overlay {
  z-index: 1000;
  opacity: 0.8;
}

.nav-language {
  margin-top: 36px;
  padding-right: 40px;
  text-transform: uppercase;

  > li {
    & + li {
      margin-left: 10px;
    }

    a {
      font-weight: normal;
      transition: background 0.3s, opacity 0.3s;
      text-transform: uppercase;
      opacity: 0.7;
      color: white;

      &:hover,
      &:focus,
      &.active {
        opacity: 1;
        color: white;
        background-color: transparent;
      }
    }
  }
}