.news-list-view {
  .news-img-wrap {
    float: left;
  }
  .media {
    &+.media {
      margin-top: 30px;
    }
    img {
      max-width: none;
    }
    .media-left {
      padding: 0;
      padding-right: 30px;

      @media (max-width: $screen-sm-max) {
        display: block;
        padding-bottom: 30px;
      }
    }
  }
  &:focus,
  .media:focus,
  a:focus {
    outline: none;
  }
  &.slick-dotted.slick-slider {
    margin-bottom: 50px;
  }
  .slick-dots {
    z-index: 1000;

    text-align: left;

    li {
      button:before {
        height: 16px;
        width: 16px;
      }
      &:hover,
      &.slick-active {
        button:before {
          opacity: 1;
          background-color: red;
        }
      }
      &:hover {
        button:before {
          opacity: 1;
          background-color: $red3;
        }
      }
    }
  }
}

.slick-dots li {
  button:before {
    content: "";
    background-color: black;
    border-radius: 50%;
    transition: background-color .3s ease-in-out;
  }
  &:hover,
  &.slick-active {
    button:before {
      opacity: 1;
      background-color: $red3;
    }
  }
}