/**
 * Basic styling of CE Besides
 */
.element-besides {
  .wrapper {
    &.right {
      img {
        width: calc(50% - 15px);
        float: right;
        padding: 0 0 $paddingMedium $paddingMedium;
      }
    }

    &.left {
      img {
        width: calc(50% - 15px);
        float: left;
        padding: 0 $paddingMedium $paddingMedium 0;
      }
    }
  }
}