/**
 * Styling element uploads
 */
.element-uploads {
	.uploads-list {
		margin: $marginNone;
		padding: $paddingNone;

		li {
			list-style: none;
		}
	}

	.uploads-link,
	.uploads-image-link {
		display: inline-block;
	}
}
