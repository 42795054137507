/**
 * Default footer layout
 */
footer {
	color: $colorTextFooter;

	.container {
		background-color: $colorBgContainerFooter;

		padding-top: 20px;
		padding-bottom: 20px;

		&:last-child {
			margin: 0 auto;
		}
	}
}
