@import "../../../../../../typo3conf/ext/dia_base/Resources/Css/Lib/fresco.css";

$fresco-skin-path: '../../../../../../typo3conf/ext/dia_base/Resources/Css/Lib/';

/* Sprite */
.fr-window-skin-fresco .fr-side-button-icon,
.fr-window-skin-fresco .fr-close-icon,
.fr-window-skin-fresco .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url('#{$fresco-skin-path}skins/fresco/sprite.svg');
}
/* fallback png sprite */
.fr-window-skin-fresco.fr-no-svg .fr-side-button-icon,
.fr-window-skin-fresco.fr-no-svg .fr-close-icon,
.fr-window-skin-fresco.fr-no-svg .fr-thumbnails-side-button-icon,
.fr-window-skin-fresco .fr-error-icon {
  background-image: url('#{$fresco-skin-path}skins/fresco/sprite.png');
}