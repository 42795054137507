/**
 * Realize Flexbox equalized height columns
 */

.row-eq-height {
	display: -ms-flexbox;
	display: -webkit-flex;
	flex-wrap: wrap;
}
@supports (flex-wrap: wrap) { /* hide from the incomplete Flexbox implementation in Firefox */
	.row-eq-height {
		display: flex;
	}
}

.row-eq-height {
	//display: -webkit-box;
	//display: -webkit-flex;
	//display: -ms-flexbox;
	//display: flex;

	div[class*="col-"] {
		& > .element {
			height: 100%;
			position: relative;
		}
	}
}

html.msie-true {
	svg {
		display: none !important;
	}
}

/** Browser Correction
* Safari */
html.browser-Safari {
	.row-eq-height {
		&:before,
		&:after {
			content: normal;
		}

		div[class*=col-] {
			display: flex;
			> .element {
				height: auto;
			}
		}
	}
}
