.cookie-info {
  display: none;
  position: relative;
  z-index: 3000;
  background: white;

  &.cookie-info-top {
    position: relative;
    padding: 20px 0;
  }

  a {
    color: black;
    font-weight: bold;
  }
}