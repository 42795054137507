/**
 * Default header styling
 */
h1 {
	font-size: $fontSizeH1;
	margin: 0 0 $marginH1 0;
}
h2 {
	font-size: $fontSizeH2;
	margin: 0 0 $marginH2 0;
}
h3 {
	font-size: $fontSizeH3;
	margin: 0 0 $marginH3 0;
}
h4 {
	font-size: $fontSizeH4;
	margin: 0 0 $marginH4 0;
}
h5 {
	font-size: $fontSizeH5;
	margin: 0 0 $marginH5 0;
}
h6 {
	font-size: $fontSizeH6;
	margin: 0 0 $marginH6 0;
}

/** DIA Link around content element */
a.link-box {
	display: block;
	height: 100%;
}

/**
 * Default wrapper element styles
 */
@import 'Wrappers';
