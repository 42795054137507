/* Standard Text content Variables
-----------------------------------------------------------------*/
$fontSizeH1: 44px;
$marginH1: 20px;
$fontSizeH2: 36px;
$marginH2: 20px;
$fontSizeH3: 28px;
$marginH3: 16px;
$fontSizeH4: 20px;
$marginH4: 10px;
$fontSizeH5: 18px;
$marginH5: 6px;
$fontSizeH6: 16px;
$marginH6: 0;

$fontSizeText: 18px;

/* Standard Colors Variables
-----------------------------------------------------------------*/
$colorBgBody: #ffffff;
$colorBgContainer: transparent;
$colorBgContainerFooter: transparent;
$colorBgContainerHeader: transparent;

$colorTextHeader: #FFFFFF;
$colorTextFooter: #FFFFFF;
$colorTextMain: #010101;

/* Standard Spacing Variables
-----------------------------------------------------------------*/
$paddingNone: 0;
$paddingDefault: 30px;
$paddingSmall: 15px;
$paddingMedium: 54px;
$paddingLarge: 108px;

$marginNone: 0;
$marginDefault: 0;
$marginSmall: 15px;
$paddingMedium: 54px;
$marginLarge: 108px;


/**
 * Defining / Overwriting default variables
 */
$colorWhite: #FFFFFF;
$colorLightGray: #F5F5F5;
$colorLighterGray: #B3B3B3;
$colorMidGray: #777777;
$colorDarkGray: #4c4c4c;
$colorGreen: #6f8f22;

//@see http://brandcolors.net/
$social-colors: (
  facebook: #3b5998,
  twitter : #55acee,
  xing: #026466,
  youtube : #cd201f,
  google : #dd4b39,
  kununu: #18242b,
  instagram: #2c2c2c
);

$em-base: $font-size-base;

// Xtra large screen / cinema display
//** Deprecated `$screen-xl` as of v3.0.1
$screen-xl:             1500px;
$screen-xl-min:         $screen-xl;
$screen-xl-desktop:     $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-lg-max:         ($screen-xl-min - 1);

//** Padding between columns. Gets divided in half for the left and right.
$container-xl:          1410px;