.panel {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0 none;
}

.panel-default {
  .panel-title a  {
    color: #666;
  }
  >.panel-heading {
    border-bottom: 1px solid #e6e6e6;
    a {
      font-weight: normal;
      font-style: italic;
      font-size: 28px;
    }
    .icon {
      float: right;
      font-size: 22px;

      &:before {
        content: $icon-minus;
        color: $red-color;
      }
    }
    .collapsed {
      .icon {
        &:before {
          content: $icon-plus;
          color: inherit;
        }
      }
    }
  }
  >.panel-collapse {
    >.element {
      padding: 40px 0;
    }
    border-bottom: 1px solid #e6e6e6;
  }
}

.panel-collapse .text p:last-child {
  margin-bottom: 0;
}