.bootstrap-select > select {

}

.content-part {
  > .container,
  > .container-fluid,
  > .element,
  > .background-wrapper {
    padding-bottom: $paddingLarge;

    @media(max-width: $screen-sm-max) {
      padding-bottom: $paddingMedium;
    }
  }
}

h2 {
  color: $red-color;
  margin: 0;
  padding-top: 25px;
  line-height: 44px;
  padding-bottom: $padding-30;
  position: relative;
  @include triangle("top");
  &:before {
    background: $red-color;
    top: 0;
    left: 0px;
    height: 3px;
    width: 43px;
  }
}

a {
  font-weight: bold;
}

.content-part {
  .sub-menu {
    width: 100%;
    margin-top: -48px;
    border-bottom: 1px solid $light-grey-color;
    padding-bottom: 14px;
    margin-bottom: 40px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        line-height: 30px;
        font-size: 20px;
        float: left;
        padding-right: 40px;
        position: relative;
        font-weight: 500;
        a {
          color: $dark-grey-color;
          display: block;
          padding-top: 9px;
          text-decoration: none;
          text-transform: uppercase;
          -webkit-transition: background 0.3s, color 0.3s;
          -msz-transition: background 0.3s, color 0.3s;
          -ms-transition: background 0.3s, color 0.3s;
          -o-transition: background 0.3s, color 0.3s;
          transition: background 0.3s, color 0.3s;
          @include triangle("top");
          &:before {
            background: $red-color;
            top: 0;
            left: 0px;
            height: 3px;
            width: 43px;
            -webkit-transform: scale3d(0, 2, 1);
            -msz-transform: scale3d(0, 2, 1);
            -ms-transform: scale3d(0, 2, 1);
            -o-transform: scale3d(0, 2, 1);
            transform: scale3d(0, 2, 1);
            -webkit-transform-origin: 0% 50%;
            -msz-transform-origin: 0% 50%;
            -ms-transform-origin: 0% 50%;
            -o-webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transition: -webkit-transform 0.3s;
            -msz-transition: -webkit-transform 0.3s;
            -ms-transition: -webkit-transform 0.3s;
            -o-transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            -webkit-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -msz-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -ms-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            -o-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
          }
          &:hover,
          &:focus {
            color: $dark-grey-color;
            @include triangle("top");
            &:before {
              -webkit-transform: scale3d(1, 1, 1);
              -msz-transform: scale3d(1, 1, 1);
              -ms-transform: scale3d(1, 1, 1);
              -o-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
          }
        }
        &.active a {
          @include triangle("top");
          &:before {
            background: $red-color;
            top: 0;
            left: 0px;
            height: 3px;
            width: 43px;
            -webkit-transform: scale3d(1, 1, 1);
            -maz-transform: scale3d(1, 1, 1);
            -ms-transform: scale3d(1, 1, 1);
            -o-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
          }
          &:hover,
          &:focus {
            @include triangle("top");
            &:before {
              background: $red-color;
              top: 0;
              left: 0px;
              height: 3px;
              width: 43px;
              -webkit-transform: scale3d(1, 1, 1);
              -msz-transform: scale3d(1, 1, 1);
              -ms-transform: scale3d(1, 1, 1);
              -o-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
            }
          }
        }
      }
    }
  }
  padding-top: 98px;
  p {
    line-height: $line-height;
    margin-bottom: $paragraph-margin-bottom;

  }
  span.detail {
    line-height: $line-height;
    margin-bottom: $paragraph-margin-bottom;
    display: inline-block;
    font-style: italic;
  }
  .medica-logo {
    background: $light-grey-color;
    padding: 15px;
    overflow: hidden;
    width: 100%;
  }
  .product-filter {
    margin-bottom: 63px;
    label {
      line-height: 30px;
      padding: 15px 0;
      font-size: 20px;
      float: left;
      padding-right: 30px;
      text-transform: uppercase;
    }
    .bootstrap-select {
      width: 100%;
      max-width: 240px;
      float: left;
      margin-right: 11px;
      .btn.btn-default {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
  .img-block {
    position: absolute;
    right: 30px;
    top: -470px;
    z-index: 100;
  }
  .panel-group {
    width: 100%;
    .accordion-box {
      .accordionRow {
        border-bottom: 1px solid $light-grey-color;
        line-height: 26px;
        padding: 22px 30px 22px 0;
        font-size: 28px;
        font-style: italic;
        position: relative;
        cursor: pointer;
        i {
          font-size: 28px;
          line-height: 35px;
          float: right;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -17px;
        }
        &.active {
          i {
            color: $red-color;
          }
        }
      }
    }
    .accordionOpen {
      width: 100%;
      overflow: hidden;
      padding: 40px 0;
      border-bottom: 1px solid $light-grey-color;
      display: none;
      .contenttable {
        margin-bottom: 20px;
        td {
          width: 50%;
        }
      }
    }
  }
  .link-back {
    margin-top: -50px;
    position: absolute;

    &:hover, &:focus {
      color: $red-color;
    }
  }
}

.content-part {
  /*padding-bottom:9px;*/
  h2 {
    color: $dark-grey-color;
    width: 100%;
    display: inline-block;
  }
  .sport-product-row {
    margin-top: 50px;
    margin-bottom: 93px;
  }
  .grid-4,
  .product-row {
    margin-bottom: 0px;

    h2 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
    a {
      margin-bottom: 0px;
      display: inline-block;
    }
  }
  figure.inline,
  .img {
    overflow: hidden;
    margin-bottom: 25px;
    position: relative;
    text-align: center;
    img {
      display: inline-block;
    }
    h2.h2 {
      position: absolute;
      bottom: 20px;
      right: 30px;
      z-index: 100;
      color: #fff;
      margin: 0;
      padding: 0;
      &:before {
        width: 0;
      }
    }
  }
  h3 {
    color: $red-color;
  }
  .text,
  .medicine-block {
    h2 {
      padding-bottom: 10px;
    }
    span.detail {
      font-size: 28px;
      margin-bottom: 15px;
      margin-top: -25px;
    }
    p {
      margin-bottom: 30px;
    }
  }
}

.products-part {
  .container-gridelements_pi1 {
    padding-bottom: 0;
  }
  padding-top: $paddingLarge;
  padding-bottom: $paddingLarge;

  @media(max-width: $screen-sm-max) {
    padding-top: $paddingMedium;
    padding-bottom: $paddingMedium;
  }
  background: $light-grey-color;
  h2 {
    color: $dark-grey-color;
    display: inline-block;
    width: 100%;
  }
  .gridcol {
    margin-bottom: 30px;
  }

  .products-box {
    background: $wight-color;
    padding: 80px 30px 70px;
    overflow: hidden;
    text-align: center;

    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      box-shadow: 6px 12px 36px rgba(0,0,0,.1);
    }

    .element-image,
    .icon-box {
      height: 100px;
      width: 100px;
      margin: 0 auto 23px;
      @include border-radius(100px);
      border: 3px solid #666666;
      padding:15px;
      figure {
        width: 100%;
        height: 100%;
        > a {
          width: 100%;
        }
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .img-responsive {
        display: inline-block;
      }
    }
    h4 {
      color: $red-color;
      margin-bottom: 23px;
      line-height: 26px;

    }
    .btn.btn-primary {
      width: 100%;
      position: relative;
      .fa {
        float: right;
        font-size: 26px;
        position: absolute;
        right: 25px;
        top: 12px;

      }
    }
  }
}

@media (max-width: $screen-sm-min) {
  h1 {
    font-size: 50px;
  }
  .content-part {
    .sub-menu {
      margin-top: 0;
      ul {
        li {
          padding-right: 10px;
          font-size: 16px;
        }
      }
    }
    padding: 30px 0 30px;
    .medica-logo {
      margin-bottom: 20px;
    }
    .content-block {
      margin-bottom: 20px;
    }
    .img-block {
      top: -270px
    }
  }
  .products-part {
    padding: 30px 0;
  }

}

@media (max-width: $grid-float-breakpoint-max) {
  h2 {
    font-size: 36px;
    //word-break: break-all;
  }
  .content-part {
    background: #fff;
    .sub-menu {
      display: none;
    }
    .img-block {
      margin-top: -250px;
      margin-bottom: 40px;
      top: 0px;
      position: relative;
      width: 100%;
      right: 0;
      text-align: center;
    }
    table {
      margin-top: 20px;
    }
    .panel-group {
      .accordion-box {
        .accordionRow {
          font-size: 22px;
        }
        .accordionOpen {
          padding-top: 20px;
        }
      }
    }
  }
  .inner-page {
    .content-part {
      .sport-product-row {
        a {
          margin-bottom: 30px;
          display: inline-block;
        }
      }
    }
  }

}