@mixin background ($folder:style1, $img:file, $type:png, $repeat:no-repeat, $x:100%, $y:100%) {
    background-image: url(../Img/#{$img}.#{$type});
    background-repeat: #{$repeat};
    background-position: #{$x} #{$y};
	background-size: #{$x} #{$y};
}

@function letter-spacing($target, $font-size-letter) {
  @return ($target * $font-size-letter) / 1000;
}

@function ls($target, $font-size-letter) {
  @return letter-spacing($target, $font-size-letter);
}

@mixin background-opacity($color, $opacity: 0.1) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}
@mixin base-triangle($triangle-width) {
  height: $triangle-width;
  width: $triangle-width;
  content:"";
  position:absolute;
}

@mixin triangle($position, $triangle-width: 43, $stroke-width: 3) {
  @if $position == top {
    &:after {
      @include base-triangle($triangle-width);
    }
  }

  @if $position == top {
    &:before {
      @include base-triangle($triangle-width);
    }
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
