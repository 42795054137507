/**
 * Default header layout
 */
header {
	color: $colorTextHeader;

	.container {
		background-color: $colorBgContainerHeader;
	}
}
