/**
 * CSS-made text splitting into columns
 */
.txt-col-sm-2,
.txt-col-md-1, .txt-col-md-2, .txt-col-md-3,
.txt-col-lg-1, .txt-col-lg-2, .txt-col-lg-3,
.txt-col-xl-1, .txt-col-xl-2, .txt-col-xl-3, .txt-col-xl-4 {
	-moz-column-gap: $grid-gutter-width;
	column-gap: $grid-gutter-width;

	> div, p, ol, ul {
		display: inline-block;
		width: 100%;
	}
}

@media (min-width: $screen-sm) {
	.txt-col-sm-2 {
		-moz-column-count: 2;
		column-count: 2;
	}
}

@media (min-width: $screen-md) {
	.txt-col-md-1 {
		-moz-column-count: 1;
		column-count: 1;
	}

	.txt-col-md-2 {
		-moz-column-count: 2;
		column-count: 2;
	}

	.txt-col-md-3 {
		-moz-column-count: 3;
		column-count: 3;
	}
}

@media (min-width: $screen-lg) {
	.txt-col-lg-1 {
		-moz-column-count: 1;
		column-count: 1;
	}

	.txt-col-lg-2 {
		-moz-column-count: 2;
		column-count: 2;
	}

	.txt-col-lg-3 {
		-moz-column-count: 3;
		column-count: 3;
	}
}

@media (min-width: $screen-xl) {
	.txt-col-xl-1 {
		-moz-column-count: 1;
		column-count: 1;
	}

	.txt-col-xl-2 {
		-moz-column-count: 2;
		column-count: 2;
	}

	.txt-col-xl-3 {
		-moz-column-count: 3;
		column-count: 3;
	}

	.txt-col-xl-4 {
		-moz-column-count: 4;
		column-count: 4;
	}
}
