/**
 * Default element spacing
 */
.container-fluid,
.container,
.element,
.flexcol {
	margin-bottom: $marginDefault;
}

.container-fluid,
.container {
	.element:last-child {
		margin-bottom: 0;
	}
}
main > div:last-of-type {
	margin-bottom: 0;
}

/**
 * Common wrapper classes styles
 */
.background-wrapper {
	padding: $paddingDefault 0;
	margin-bottom: $marginDefault;

	.container-fluid:last-child,
	.container:last-child,
	.element:last-child {
		margin-bottom: 0;
	}
}

.element-background-wrap {
	height: 100%;
}

/**
 * Some gridelements special styles
 */
.element.grid-1 {
	position: relative;
}

/**
 * Default spacing classes
 */
.no-margin {
	margin: 0;
}

.no-margin-above {
	margin-top: $marginNone !important;
}
.no-margin-below {
	margin-bottom: $marginNone !important;
}

.margin-above-default {
	margin-top: $marginDefault !important;
}
.margin-below-default {
	margin-bottom: $marginDefault !important;
}

.margin-above-sm {
	margin-top: $marginSmall !important;
}
.margin-below-sm {
	margin-bottom: $marginSmall !important;
}

.margin-above-md {
	margin-top: $marginMedium !important;
}
.margin-below-md {
	margin-bottom: $marginMedium !important;
}

.margin-above-lg {
	margin-top: $marginLarge !important;
}
.margin-below-lg {
	margin-bottom: $marginLarge !important;
}

/**
 * Default padding classes
 */
.no-padding {
	padding: $paddingNone !important;
}

.no-padding-above {
	padding-top: $paddingNone !important;
}
.no-padding-below {
	padding-bottom: $paddingNone !important;
}

.padding-above-default {
	padding-top: $paddingDefault !important;
}
.padding-below-default {
	padding-bottom: $paddingDefault !important;
}

.padding-above-sm {
	padding-top: $paddingSmall !important;
}
.padding-below-sm {
	padding-bottom: $paddingSmall !important;
}

.padding-above-md {
	padding-top: $paddingMedium !important;
}
.padding-below-md {
	padding-bottom: $paddingMedium !important;
}

.padding-above-lg {
	padding-top: $paddingLarge !important;
}
.padding-below-lg {
	padding-bottom: $paddingLarge !important;
}
