.mfp-close {
  color: #fff !important;
  display: inline-block;
  position: fixed;
  top: 15px;
  right: 15px;
  font-size: 30px;
  transition: transform 0.3s ease-out;
  transform-origin: center;

  &:active {
    transform: scale(0.6) rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand',
            M11=1.5320888862379554, M12=-0.6855752193730787,
            M21=0.6855752193730796, M22=1.5320888862379558);
    top: 15px;
  }

  // Sometimes the button is added twice
  button {
    display: none;
  }
}

.video-popup {
  text-align: center;

  video {
    width: 100%;
    max-width: 80%;
    height: auto;
  }
}