body{
  font-weight: 300;
  background-color: #333;
  img{
    height:auto;
  }
}

#page {
  position: relative;
  background-color: white;
}
.page-wrap {
  position: relative;
}

.wf-loading body{
  visibility:hidden
}
.no-js .wf-loading body{
  visibility:visible
}
.wf-active body {
  visibility:visible;
}

.container {
  max-width:1296px;
  width: 100%;
}
img{
  max-width:100%;
  outline:none;
}
.btn-group.open
.dropdown-toggle{
  box-shadow:none;
}
a{
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
input[type="submit"]{
  text-transform:uppercase;
  font-weight: 400;
  border:none;
  text-align:left;
  background:$grey-color;
  color:#fff;
  line-height:40px;
  padding:10px 30px;
  display:inline-block;
  width:100% !important;
  text-align:center;
  @include border-radius(35px);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;

  max-width:282px;

  &:hover{
    background:$red-color;
  }
}
.bootstrap-select .btn:focus{
  outline:0px auto -webkit-focus-ring-color !important;
  outline-offset:0px;
}
.btn{
  outline:none;
  outline-offset:0px;
  text-transform:uppercase;
  font-weight: 400;
  border:none;
  text-align:center;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;

  &.btn-primary
  {
    border: 0 none;
    color:#fff;
    line-height:40px;
    padding:11px 30px 9px;
  }
  a{
    color:#fff;
    display:block;
    outline:none;
  }
  &.bg-info{
    background:$grey-color;
    width: auto;
    &:hover{
      background:$red-color;
    }
  }
  &.btn-default{
    line-height:40px;
    padding:7px 30px 3px;
    text-decoration:none;
    background:$grey-color;
    &.active{
      a{line-height:50px;}
    }
    &:focus,
    &:hover,
    &.active{
      background:$red-color;
      color:#fff;
    }
  }
  &.dropdown-toggle.btn-default{
    a{
      line-height:40px;
      padding:7px 30px 3px;
      text-decoration:none;
    }
    background:none;
    &.active{
      border:2px solid $red-color;
      a{line-height:50px;}
    }
    &:focus,
    &:hover,
    &.active{
      background:none;
      color:$dark-grey-color;
      border:2px solid $red-color;
    }
  }
}
.bs-caret{
  @include background(style1,arrow,png,no-repeat);
  height:12px;
  width:16px;
  background-position: right center;
  display: inline-block;
  position: relative;
  right:8px;
}

input[type="search"],
input[type="email"],
textarea,
input[type="text"]{
  width:100%;
  line-height:40px;
  padding:10px 15px;
  border:none;
  border-bottom:2px solid $grey-color;
  font-size:18px;
  background:none;
  color:#808080;
  outline:none;
  @include border-radius(0px);
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  &:focus,
  &:hover,
  &.active{
    border-bottom:2px solid $red-color;
    color:#999999;
  }
}
textarea{
  height:100px;
  resize: none;
  line-height:25px;
}
.tx-powermail{
  .container-fluid{
    padding:0;
  }
  .powermail_field{
    margin-bottom:20px;
  }
  label{
    font-weight:normal;
    font-size:18px;
  }
  .powermail_fieldwrap_type_radio{
    .powermail_label{
      margin-bottom:20px;
      float: left;
      padding-right:20px;
      width:100px;
      line-height: 30px;
    }
    .radio{
      float:left;
      margin:0 15px 0 0;
    }
    .powermail_field
    {
      padding-bottom: 20px;
      width:auto;
      display: inline-block;
    }
  }

  .powermail_fieldwrap_type_select{
    .powermail_label{
      line-height:50px;
      padding-top:5px;
      float:left;
      padding-right:20px;
      width:80px;
    }
    .powermail_field{
      float:left;
    }
  }
  .powermail_fieldwrap {
    float:left;
    width:100%;
  }
}
ul{
  padding:0 20px;
  margin-bottom:30px;
}
h1,h4,h5,h6{
  margin-bottom:20px;
}
.table{
  margin:15px 0;
}

.tx-powermail h3,
.tx-powermail legend{display:none;}
label.label_radio{
  overflow: hidden;
  input{
    position: absolute;
    left: -880px;
    top: 0;
  }
  @include background(style1,label_radio,png,no-repeat);
  padding-left:30px;
  line-height:30px;
  background-position: left 10px;
  background-size: auto auto;
  &.r_on{
    @include background(style1,label_radio,png,no-repeat);
    background-position: left -20px;
    background-size: auto auto;
  }
}
label.label_check{
  overflow: hidden;
  input{
    position: absolute;
    left: -880px;
    top: 0;
  }
  @include background(style1,label_check,png,no-repeat);
  padding-left:30px;
  line-height:30px;
  background-position: left 9px;
  background-size: auto auto;
  &.c_on{
    @include background(style1,label_check,png,no-repeat);
    background-position:left -19px;
    background-size:auto auto;
  }
}
.parsley-errors-list{
  padding:0;
  list-style:none;
  margin:0;
  width:100%;
  display:inline-block;
  font-size:15px;
  li{
    color:$red-color;
  }
}
#page.inner-page .content-part .tx-indexedsearch {
  margin-bottom:80px;
  .browsebox{
    width:100%;
    text-align:center;
    display:inline-block;
    margin:0;
    padding:0;
    margin-top:20px;
    li{
      display:inline-block;
      width:45px;
      height:45px;
      line-height:45px;
      font-size:16px;
      border:2px solid $grey-color;
      @include border-radius(100px);
      -webkit-transition: all 150ms ease-in-out;
      -moz-transition: all 150ms ease-in-out;
      -ms-transition: all 150ms ease-in-out;
      -o-transition: all 150ms ease-in-out;
      transition: all 150ms ease-in-out;
      a {
        color:$dark-grey-color;
        text-decoration:none;
        display:block;
        line-height: 40px;
      }
      &.tx-indexedsearch-browselist-currentPage,
      &:hover{
        border:2px solid $red-color;
        background:$red-color;
        display:inline-block;
        a{
          color:#fff;
        }
      }
    }
  }
  legend{
    display:none;
  }
  .tx-indexedsearch-browsebox
  {
    text-align:center;
  }
  h3{
    margin:50px 0 10px 0;
    color:$red-color;
    font-weight:500;
    font-size:26px;
    a{
      color:$red-color;
    }
  }
  p{
    margin:0;
    strong{
      color:$dark-grey-color;
    }
  }
  dl dd a{
    color:$red-color;
  }
  .tx-indexedsearch-info{
    background:none;
  }
  .percent-tmpl-css{
    margin:0;
  }
  #tx_indexedsearch{
    width:100%;
    padding:20px;
    background:$light-grey-color;
    display:inline-block;
    .tx-indexedsearch-form{
      width:70%;
      float:left;
      padding-right:20px;
      label{
        width:30%;
        float:left;
        line-height:49px;
        margin:0;
      }
      .tx-indexedsearch-searchbox-sword{
        width:70%;
        float:left;
        padding-top:3px;
        padding-bottom:3px;
      }
    }
    .tx-indexedsearch-search-submit{
      width:30%;
      float:left;
      .submit{
        max-width:100%;
        padding:5px;
      }
    }
  }
}
@media (max-width: $grid-float-breakpoint-max) {
  #page.inner-page {
    .content-part{
      .tx-indexedsearch {
        #tx_indexedsearch {
          padding:20px;
          .tx-indexedsearch-form{
            width:100%;
            margin-bottom:0;
            padding:0;
            label{
              width:100%;
              line-height:30px;
            }
            .tx-indexedsearch-searchbox-sword{
              width:100%;
              padding:0;
            }
          }
          .tx-indexedsearch-search-submit{
            width:100%;
            margin-top:10px;
          }
        }
      }

    }
  }
}
@media (max-width:410px) {
  .tx-powermail{
    label{
      font-size:14px;
    }
  }
}

.row-eq-height {
  .gridcol {
    > .element {
      width: 100%;
    }
  }
}