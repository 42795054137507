$icomoon-font-path: "fonts" !default;

$icon-facebook: "\e900";
$icon-lock: "\e66b";
$icon-envelope: "\e696";
$icon-file-empty: "\e6b3";
$icon-telephone: "\e76a";
$icon-magnifier: "\e922";
$icon-cross2: "\e935";
$icon-plus: "\e936";
$icon-minus: "\e937";
$icon-chevron-down: "\e93a";
$icon-chevron-left: "\e93b";
$icon-chevron-right: "\e93c";

