.element-stacked {
  img {
    margin-bottom: 30px;
    width: 100%;
  }
  .title {
    h3 {
      margin-bottom: 30px;
    }
  }
  .text {
    margin-bottom: 30px;
  }

  .boxed {
    img {
      -webkit-filter: grayscale(100%) brightness(90%) contrast(90%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%) brightness(90%) contrast(90%);
    }
    figcaption {
      left: 30px;
      right: 30px;
      bottom: 30px;
      text-align: right;

      h3 {
        margin: 0!important;
        color: white!important;
        font-size: 30px;
        font-weight: normal;
      }
    }
  }
  .image-description {
    font-size: 0.8em;
  }
}