$greySoft1: #EEEEEE;
$greySoft2: #BBBBBB;
$greySoft3: #AAAAAA;
$grey1: #444444;
$blue1: #1E5799;
$orange1: #FCF8E3;
$orange2: #FAEBCC;
$orange3: #FFCA4B;
$red1: #F2DEDE;
$red2: #EBCCD1;
$red3: #A94442;
$green1: #CDEACA;
$green2: #58B548;
$green3: #3B7826;
