/**
 * Styling of special classes used for positioning content elements
 */
.position-bottom-left {
	position: absolute;
	left: 0;
	bottom: 0;
}
.position-bottom-right {
	position: absolute;
	right: 0;
	bottom: 0;
}

.position-top-left {
	position: absolute;
	left: 0;
	top: 0;
}
.position-top-right {
	position: absolute;
	right: 0;
	top: 0;
}
