/**
 * Popup for displaying warning when a visitor is using deprecated browsers
 */
#browserWarning {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 90000;

	display: flex;
	align-items: center;
	justify-content: center;

	.lightbox- {
		&inner {
			padding: 15px;
			text-align: center;

			h2 {
				margin: 15px 0;
			}
			p {
				margin: 0 0 10px;
			}
		}

		&close {
			position: absolute;
			display: inline-block;
			top: 20px;
			right: 30px;
			z-index: 10;
			cursor: pointer;
			font-size: 30px;
		}
	}

	.choseYourBrowser {
		font-size: 30px;
		text-align: center;

		a {
			display: inline-block;
			margin: 0 3px;
			opacity: 0;
			text-decoration: none;

			-webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
			-moz-animation: fadein .5s; /* Firefox < 16 */
			-ms-animation: fadein .5s; /* Internet Explorer  > 9*/
			-o-animation: fadein .5s; /* Opera < 12.1 */
			animation: fadein .5s;

			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;

			&:nth-child(2) {
				animation-delay: .5s;
			}
			&:nth-child(3) {
				animation-delay: 1s;
			}
			&:nth-child(4) {
				animation-delay: 1.5s;
			}
			&:nth-child(5) {
				animation-delay: 2s;
			}

			i {
				-webkit-transition: opacity .2s ease-in-out;
				-moz-transition: opacity .2s ease-in-out;
				-ms-transition: opacity .2s ease-in-out;
				-o-transition: opacity .2s ease-in-out;
				transition: opacity .2s ease-in-out;

				&:hover {
					opacity: .6;
				}
			}
		}
	}
}

/** IE9 does not support the animation, so display from the beginning in IE */
.msie-true #browserWarning .choseYourBrowser a {
    opacity: 1;
}
