@import "variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?e63lxx');
  src:  url('#{$icomoon-font-path}/icomoon.eot?e63lxx#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.woff2?e63lxx') format('woff2'),
    url('#{$icomoon-font-path}/icomoon.ttf?e63lxx') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?e63lxx') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?e63lxx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon,
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}
.icon-file-empty {
  &:before {
    content: $icon-file-empty;
  }
}
.icon-telephone {
  &:before {
    content: $icon-telephone;
  }
}
.icon-magnifier {
  &:before {
    content: $icon-magnifier;
  }
}
.icon-cross2 {
  &:before {
    content: $icon-cross2;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}

